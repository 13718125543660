<template>
    <router-link :to="card.link" class="info-banner">
        <span class="info-banner__type" :style="`background: url('${'/assets/icons/' + card.photo}') center / cover`"></span>
        <p class="info-banner__date">{{ dateArr(card.date) }}</p>
        <p class="info-banner__title">{{ card.title }}</p>
        <p class="info-banner__text ckEditor-html" v-trim="2" v-html="card.text"></p>
    </router-link>
</template>
<script>
export default {
  name: 'BillsCard',
  props: {
    card: {
      type: Object,
      required: true,
      default: () => ({
        date: null,
        title: null,
        text: null,
        photo: null,
        link: '/'
      })
    }
  },
  methods: {
    dateArr (date) {
      return (new Date(date).toLocaleString('ru', { day: 'numeric', month: 'long', year: 'numeric' }).slice(0, -3))
    }
  }
}
</script>
<style lang="scss" scoped>
.ckEditor-html {
  max-height: 2.5rem;
  ::v-deep {
    * {
      margin: revert;
      padding: revert;
      list-style-type: revert;
      font-size: revert;
      font-weight: revert;
    }
    :nth-child(n){
      margin: 0;
    }
  }
}
.info-banner {
  margin-bottom: 1.88rem;
  padding: 1.875rem;
  width: 26.56rem;
  height: 14.25rem;

  box-shadow: 0px .94rem 1.56rem rgba(189, 205, 221, 0.35);
  border-radius: .5rem;
  border: .06rem solid #E5E8EE;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.875rem;

  &__type {
    width: 2.8125rem;
    height: 2.8125rem;
    margin-bottom: auto;
    // margin-bottom: 1.25rem;
  }
  &__date{
    font-size: .875rem;
    line-height: 1.37rem;
    color: #5E686C;
    opacity: 0.8;
    margin-bottom: .625rem;
  }

  &__title{
    font-weight: 600;
    font-size: .875rem;
    line-height: 1.38rem;
    color: #1F3245;
    margin-bottom: .312rem;
  }

  &__text{
    font-size: .875rem;
    line-height: 1.38rem;
    color: #171717;
    opacity: 0.8;
  }

}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .info-banner {
        &__title {
            width: 100% !important;
        }

        &__text {
            width: 100% !important;
        }
    }
}
</style>
