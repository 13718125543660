<template>
    <div>
        <bread-crumb :navigationArray="navigationLink"/>
        <div class="white-container">
            <h5>Актуальные темы</h5>
            <tricolor-line />
            <div class="contain">
                <bills-card v-for="(survey, key) in getPublicTopics" :key="`link${key}`" :card="survey" class="bills_card"/>
            </div>
            <pagination-block class="news__pagination" :current="currentPage" :total="countPage" @page-changed="getPage" />
        </div>
    </div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb'
import TricolorLine from '@/components/TricolorLine'
import BillsCard from '@/components/activitiesSurveys/BillsCard'
import Pagination from '@/components/Pagination'

export default {
  name: 'ActualsTopics',
  components: {
    'bread-crumb': BreadCrumb,
    'tricolor-line': TricolorLine,
    'bills-card': BillsCard,
    'pagination-block': Pagination
  },
  data () {
    return {
      currentPage: 1,
      countPage: 2,
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'Деятельность',
          path: '/actual'
        },
        {
          title: 'Актуальные темы'
        }
      ],
      actualsTopics: [
        {
          date: '01.01.2020',
          title: 'Законопроект о противодействии коррупции',
          subtitle: 'Новый законопроект относится к противодействию коррупции на территории РФ и Новый законопроект относится',
          photo: 'thunderbolt.svg',
          link: '/current_topic'
        },
        {
          date: '01.01.2020',
          title: 'Законопроект о противодействии коррупции',
          subtitle: 'Новый законопроект относится к противодействию коррупции на территории РФ и Новый законопроект относится',
          photo: 'thunderbolt.svg',
          link: '/current_topic'
        },
        {
          date: '01.01.2020',
          title: 'Законопроект о противодействии коррупции',
          subtitle: 'Новый законопроект относится к противодействию коррупции на территории РФ и Новый законопроект относится',
          photo: 'thunderbolt.svg',
          link: '/current_topic'
        },
        {
          date: '01.01.2020',
          title: 'Законопроект о противодействии коррупции',
          subtitle: 'Новый законопроект относится к противодействию коррупции на территории РФ и Новый законопроект относится',
          photo: 'thunderbolt.svg',
          link: '/current_topic'
        },
        {
          date: '01.01.2020',
          title: 'Законопроект о противодействии коррупции',
          subtitle: 'Новый законопроект относится к противодействию коррупции на территории РФ и Новый законопроект относится',
          photo: 'thunderbolt.svg',
          link: '/current_topic'
        },
        {
          date: '01.01.2020',
          title: 'Законопроект о противодействии коррупции',
          subtitle: 'Новый законопроект относится к противодействию коррупции на территории РФ и Новый законопроект относится',
          photo: 'thunderbolt.svg',
          link: '/current_topic'
        },
        {
          date: '01.01.2020',
          title: 'Законопроект о противодействии коррупции',
          subtitle: 'Новый законопроект относится к противодействию коррупции на территории РФ и Новый законопроект относится',
          photo: 'thunderbolt.svg',
          link: '/current_topic'
        },
        {
          date: '01.01.2020',
          title: 'Законопроект о противодействии коррупции',
          subtitle: 'Новый законопроект относится к противодействию коррупции на территории РФ и Новый законопроект относится',
          photo: 'thunderbolt.svg',
          link: '/current_topic'
        }
      ]
    }
  },
  created () {
    this.$store.dispatch('getPublicTopics')
  },
  computed: {
    getPublicTopics () {
      return this.$store.getters.getPublicTopics.map((topic) => {
        return {
          title: topic.title,
          date: topic.date,
          text: topic.description,
          photo: 'thunderbolt.svg',
          link: '/actuals_topic/' + topic.id
        }
      })
    }
    // getTotalPage () {
    //   return this.$store.getters.getContent.total ? this.$store.getters.getContent.total : 50
    // }
  },
  methods: {
    getPage (page) {
      console.log(page)
      this.currentPage = page
    }
  }
}
</script>
<style lang="scss" scoped>
.white-container{
    width: auto;
    margin: 0 5rem;
    padding: 2.5rem 2.94rem 0.625rem 2.95rem;
}
h5{
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;
    margin-bottom: 1.81rem;
}
.contain{
    display: flex;
    flex-wrap: wrap;
    margin: 2rem -1.06rem 0 -1.06rem;
}
.bills_card{
    margin: 0 1.06rem 1.87rem 1.06rem;
    width: calc(33.333% - 2.125rem);
}
.news__pagination{
    margin-top: 0.625rem;
    padding: 2.5rem 0 1.87rem 0;
    border-top: .0625rem solid #D5D6D9;
}
@media screen and (max-width: 768px){
  .white-container{
    width: auto;
    margin: 0 .75rem;
    padding: 1.86rem 1.25rem;
  }
  .bills_card{
    width: calc(50% - 2.125rem);
  }
}
@media screen and (max-width: 420px){
    .white-container{
        width: 100%;
        margin: 0;
        padding: 1.88rem 1rem;
    }
    .bills_card{
      width: calc(100% - 2.125rem);
    }
}
</style>
